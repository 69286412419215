export const ALGOLIA_APP_ID = "CU9ILK4MOL"
export const ALGOLIA_READ_KEY = "3cfba1e8d677874494213dabaff189b6"
export const ALGOLIA_TEST_INDEX = "dev_tests_index"
export const ALGOLIA_ASSESSMENT_INDEX = "dev_assessment_index"
export const ALGOLIA_CANDIDATE_INDEX = "dev_candidate_index"
export const ALGOLIA_WORKSPACE_INDEX = "dev_workspace_index"
export const ALGOLIA_TEAM_INDEX = "dev_team_index"
export const ALGOLIA_DEFAULT_INDEX = "dev_default_index"

export const MODAL_LIMIT = 10
export const LIST_LIMIT = 3

export const SHOW_LIMIT = 10
