import { configure } from "vee-validate"

export function initVeeValidate() {
    // Updating default vee-validate configuration
    configure({
        validateOnBlur: false,
        validateOnChange: true,
        validateOnInput: false,
        validateOnModelUpdate: true,
    })
}
