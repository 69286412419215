import { GetterTree } from "vuex"
import { State } from "./state"
import { RootState } from "@/store"
import { TypeCompanyQuestion, TypeCompanyTest } from "./types"

export type Getters = {
    getCompanyTestDetails(state: State): TypeCompanyTest
    getSingleSelectModalStatus(state: State): boolean
    getMultipleSelectModalStatus(state: State): boolean
    getCompanyTestQuestionsList(state: State): TypeCompanyQuestion
    getCurrentAccordionValue(state: State): string
    getTotalCompanyTestScore(state: State): number
    getCompanyTestSkills(state: State): any
    getAiTestCreationStatus(state: State): boolean
}

export const getters: GetterTree<State, RootState> & Getters = {
    getCompanyTestDetails(state) {
        return state.companyTest
    },
    getCompanyTestSkills(state) {
        return state.companyTest.coveredSkillList
    },
    getSingleSelectModalStatus(state) {
        return state.openSingleSelectModal
    },
    getMultipleSelectModalStatus(state) {
        return state.openMultipleSelectModal
    },
    getCompanyTestQuestionsList(state) {
        return state.companyQuestions
    },
    getTotalCompanyTestScore(state) {
        let totalscore = 0
        state.companyQuestions.forEach((question: any) => {
            totalscore += question.maximumScore
        })
        return totalscore
    },
    getCurrentAccordionValue(state) {
        return state.activeAccordion
    },
    getAiTestCreationStatus(state) {
        return state.isAiTestInProgress
    },
}
