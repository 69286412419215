import { GetterTree } from "vuex"
import { RootState } from "../../index"
import { State } from "./state"

export type Getters = {
    getCreateAssessmentPermission(state: State): boolean
    getDeleteAssessmentPermission(state: State): boolean
    getArchiveAssessmentPermission(state: State): boolean
    getCopyAssessmentPermission(state: State): boolean
    getInviteCandidatePermission(state: State): boolean
    getDeleteCandidatePermission(state: State): boolean
    getUserPermission(state: State): boolean
    getAddUserPermission(state: State): boolean
    getChangeUserRolePermission(state: State): boolean
    getChangeUserStatusPermission(state: State): boolean
    getRolesPermission(state: State): boolean
    getDeveloperPermission(state: State): boolean
    getWhiteLabelPermission(state: State): boolean
    getSubscriptionPermission(state: State): boolean
}

export const getters: GetterTree<State, RootState> & Getters = {
    getCreateAssessmentPermission(state) {
        return state.assessmentCreate
    },
    getDeleteAssessmentPermission(state) {
        return state.assessmentDelete
    },
    getArchiveAssessmentPermission(state) {
        return state.assessmentArchive
    },
    getCopyAssessmentPermission(state) {
        return state.assessmentCopy
    },
    getInviteCandidatePermission(state) {
        return state.candidateInvite
    },
    getDeleteCandidatePermission(state) {
        return state.candidateDelete
    },
    getUserPermission(state) {
        return state.settingsUser
    },
    getAddUserPermission(state) {
        return state.settingsUserAdd
    },
    getChangeUserRolePermission(state) {
        return state.settingsUserChangeRole
    },
    getChangeUserStatusPermission(state) {
        return state.settingsUserChangeStatus
    },
    getRolesPermission(state) {
        return state.settingsRolesAndPermissions
    },
    getDeveloperPermission(state) {
        return state.settingsDeveloperPermission
    },
    getWhiteLabelPermission(state) {
        return state.settingsWhiteLabelPermission
    },
    getSubscriptionPermission(state) {
        return state.billingManageSubscription
    },
}
