// @ts-ignore
import mixpanel from "mixpanel-browser"

// diable client-side mixpanel
// use window.collectEvent instead
const isProd = false

export default function useMixpanel() {
    const initialise = () => {
        if (isProd) {
            mixpanel.init("871040dcaed1932b83282b5077a2015c", {
                debug: true,
                ignore_dnt: true,
            })
        }
    }

    const trackEvent = (eventName: string, data?: any) => {
        if (isProd) {
            mixpanel.track(eventName, data)
        }
    }

    // uniqueId for Testlify's use case is the user's email
    // https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpanelidentify
    const identify = (uniqueId: string) => {
        if (isProd) {
            mixpanel.identify(uniqueId)
        }
    }

    // https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpanelalias
    const alias = (newId: string, existingId: string) => {
        if (isProd) {
            mixpanel.alias(newId, existingId)
        }
    }

    const getDistinctId = (): string => {
        if (isProd) {
            return mixpanel.get_distinct_id()
        } else return ""
    }

    // https://help.mixpanel.com/hc/en-us/articles/115004708186-Profile-Properties
    // https://help.mixpanel.com/hc/en-us/articles/115004501966-User-Profiles-Overview
    // data param has to be an object
    const setPeopleProperties = (data: any) => {
        if (isProd) {
            mixpanel.people.set(data)
        }
    }

    return {
        getDistinctId,
        trackEvent,
        initialise,
        identify,
        alias,
        setPeopleProperties,
    }
}
