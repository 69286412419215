import { MutationTree } from "vuex"
import type { State } from "./state"

export enum MutationType {
    SET_CREATE_ASSESSMENT_PERMISSION = "SET_CREATE_ASSESSMENT_PERMISSION",
    SET_DELETE_ASSESSMENT_PERMISSION = "SET_DELETE_ASSESSMENT_PERMISSION",
    SET_ARCHIVE_ASSESSMENT_PERMISSION = "SET_ARCHIVE_ASSESSMENT_PERMISSION",
    SET_COPY_ASSESSMENT_PERMISSION = "SET_COPY_ASSESSMENT_PERMISSION",
    SET_INVITE_CANDIDATE_PERMISSION = "SET_INVITE_CANDIDATE_PERMISSION",
    SET_DELETE_CANDIDATE_PERMISSION = "SET_DELETE_CANDIDATE_PERMISSION",
    SET_USER_PERMISSION = "SET_USER_PERMISSION",
    SET_ADD_USER_PERMISSION = "SET_ADD_USER_PERMISSION",
    SET_CHANGE_USER_ROLE_PERMISSION = "SET_CHANGE_USER_ROLE_PERMISSION",
    SET_CHANGE_USER_STATUS_PERMISSION = "SET_CHANGE_USER_STATUS_PERMISSION",
    SET_ROLES_PERMISSION = "SET_ROLES_PERMISSION",
    SET_DEVELOPER_PERMISSION = "SET_DEVELOPER_PERMISSION",
    SET_WHITE_LABEL_PERMISSION = "SET_WHITE_LABEL_PERMISSION",
    SET_SUBSCRIPTION_PERMISSION = "SET_SUBSCRIPTION_PERMISSION",
}

export type Mutations = {
    [MutationType.SET_CREATE_ASSESSMENT_PERMISSION](
        state: State,
        value: boolean
    ): void
    [MutationType.SET_DELETE_ASSESSMENT_PERMISSION](
        state: State,
        value: boolean
    ): void
    [MutationType.SET_ARCHIVE_ASSESSMENT_PERMISSION](
        state: State,
        value: boolean
    ): void
    [MutationType.SET_COPY_ASSESSMENT_PERMISSION](
        state: State,
        value: boolean
    ): void
    [MutationType.SET_INVITE_CANDIDATE_PERMISSION](
        state: State,
        value: boolean
    ): void
    [MutationType.SET_DELETE_CANDIDATE_PERMISSION](
        state: State,
        value: boolean
    ): void
    [MutationType.SET_ADD_USER_PERMISSION](state: State, value: boolean): void
    [MutationType.SET_USER_PERMISSION](state: State, value: boolean): void
    [MutationType.SET_CHANGE_USER_ROLE_PERMISSION](
        state: State,
        value: boolean
    ): void
    [MutationType.SET_CHANGE_USER_STATUS_PERMISSION](
        state: State,
        value: boolean
    ): void
    [MutationType.SET_ROLES_PERMISSION](state: State, value: boolean): void
    [MutationType.SET_DEVELOPER_PERMISSION](state: State, value: boolean): void
    [MutationType.SET_WHITE_LABEL_PERMISSION](
        state: State,
        value: boolean
    ): void
    [MutationType.SET_SUBSCRIPTION_PERMISSION](
        state: State,
        value: boolean
    ): void
}

export const mutations: MutationTree<State> & Mutations = {
    [MutationType.SET_CREATE_ASSESSMENT_PERMISSION](state, value) {
        state.assessmentCreate = value
    },
    [MutationType.SET_DELETE_ASSESSMENT_PERMISSION](state, value) {
        state.assessmentDelete = value
    },
    [MutationType.SET_ARCHIVE_ASSESSMENT_PERMISSION](state, value) {
        state.assessmentArchive = value
    },
    [MutationType.SET_COPY_ASSESSMENT_PERMISSION](state, value) {
        state.assessmentCopy = value
    },
    [MutationType.SET_INVITE_CANDIDATE_PERMISSION](state, value) {
        state.candidateInvite = value
    },
    [MutationType.SET_DELETE_CANDIDATE_PERMISSION](state, value) {
        state.candidateDelete = value
    },
    [MutationType.SET_USER_PERMISSION](state, value) {
        state.settingsUser = value
    },
    [MutationType.SET_ADD_USER_PERMISSION](state, value) {
        state.settingsUserAdd = value
    },
    [MutationType.SET_CHANGE_USER_ROLE_PERMISSION](state, value) {
        state.settingsUserChangeRole = value
    },
    [MutationType.SET_CHANGE_USER_STATUS_PERMISSION](state, value) {
        state.settingsUserChangeStatus = value
    },
    [MutationType.SET_ROLES_PERMISSION](state, value) {
        state.settingsRolesAndPermissions = value
    },
    [MutationType.SET_DEVELOPER_PERMISSION](state, value) {
        state.settingsDeveloperPermission = value
    },
    [MutationType.SET_WHITE_LABEL_PERMISSION](state, value) {
        state.settingsWhiteLabelPermission = value
    },
    [MutationType.SET_SUBSCRIPTION_PERMISSION](state, value) {
        state.billingManageSubscription = value
    },
}
