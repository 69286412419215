import axios from "axios"
import useCatchHandler from "@/services/catchErrorHandler"
import useOAuth from "@/services/oauth"
import { useStore } from "@/store"
import { computed } from "vue"

export default function useApiInviteMember() {
    const { handleError } = useCatchHandler()
    const { API_PREFIX } = useOAuth()

    const store = useStore()
    const getApiRequestHeader = computed(
        () => store.getters.getApiRequestHeader
    )

    const apiWorkspaceTeam = async (
        limit: number,
        skip: number,
        query: string,
        userStatus: string,
        userRoleId: string,
        colName: string,
        inOrder: string
    ) => {
        try {
            let response: any = await axios.get(
                `${API_PREFIX}/workspace/team?limit=100&skip=0&query=${encodeURIComponent(
                    query
                )}&userStatus=${userStatus}&userRoleId=${userRoleId}&colName=${colName}&inOrder=${inOrder}`,
                getApiRequestHeader.value
            )
            return response.data
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }

    const inviteMemberNew = async (members: Array<object>) => {
        try {
            const body = { members }
            return await axios.post(
                `${API_PREFIX}/workspace/invite/user`,
                body,
                getApiRequestHeader.value
            )
        } catch ({ response }) {
            return response.data
        }
    }
    const changeStatus = async (id: string, userStatus: string) => {
        try {
            const body = {
                id,
                userStatus,
            }
            return await axios.put(
                `${API_PREFIX}/workspace/user/status`,
                body,
                getApiRequestHeader.value
            )
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }
    const deleteTeamMember = async (id: string) => {
        try {
            return await axios.delete(
                `${API_PREFIX}/workspace/user/${id}`,
                getApiRequestHeader.value
            )
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }

    const ReinviteTeamMember = async (members: Array<object>) => {
        try {
            const body = { members }
            return await axios.post(
                `${API_PREFIX}/workspace/invite/user`,
                body,
                getApiRequestHeader.value
            )
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }

    const updateMemberRole = async (
        id: string,
        role: string,
        userRoleId: string
    ) => {
        try {
            const body = {
                id,
                role,
                userRoleId,
            }
            return await axios.put(
                `${API_PREFIX}/workspace/user/profile/role`,
                body,
                getApiRequestHeader.value
            )
            return true
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }
    const apiTeamUserRegistration = async (key: string) => {
        try {
            let response: any = await axios.get(
                `${API_PREFIX}/public/auth/config/user/${key}`
            )
            return response
        } catch ({ response }) {
            return response
        }
    }
    const apiGetLongLinkUrl = async (shortId: string) => {
        try {
            let response: any = await axios.get(
                `${API_PREFIX}/workspace/register/user/${shortId}`,
                getApiRequestHeader.value
            )
            return response
        } catch ({ response }) {
            return response
        }
    }

    return {
        apiWorkspaceTeam,
        inviteMemberNew,
        changeStatus,
        deleteTeamMember,
        ReinviteTeamMember,
        updateMemberRole,
        apiTeamUserRegistration,
        apiGetLongLinkUrl,
    }
}
