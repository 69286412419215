import moment from "moment"
import helperFunction from "@/helper-functions/helperFunctions"
import { ActionTypes as AuthActions } from "@/store/modules/auth/actions"
import { useStore } from "@/store"

const { getSessionCookie, getJwtExpiration, setSessionCookie } =
    helperFunction()
const store = useStore()
const resellerAssessmentHosts = [
    "assessments.join-staging.com",
    "assessments.join.com",
]

const checkLastRefreshed = (to) => {
    try {
        const resellerProfileCookie: any =
            getSessionCookie("join_testlify_profile") || "{}"
        const testlifyProfileCookie: any =
            getSessionCookie("testlify_profile") || "{}"
        const resellerProfileInfo = JSON.parse(resellerProfileCookie)
        const testlifyProfileInfo = JSON.parse(testlifyProfileCookie)

        let updatedAt = resellerProfileInfo?.updatedAt
        let refreshedAt = testlifyProfileInfo?.refreshedAt

        if (
            !refreshedAt ||
            (updatedAt &&
                moment(moment(refreshedAt)).isBefore(moment(updatedAt)))
        ) {
            handleCookieDeleted(to)
        }
    } catch (error) {
        handleCookieDeleted(to)
    }
}

const setLastRefreshed = () => {
    // @ts-ignore
    const testlifyProfileInfo = { refreshedAt: moment.utc().format() }
    setSessionCookie(
        encodeURI(JSON.stringify(testlifyProfileInfo)),
        "testlify_profile"
    )
}

const checkTokenExpiration = (token, to) => {
    const expirationTime = getJwtExpiration(token)
    const currentUtcTime = moment.utc()
    const isExpired = currentUtcTime.isAfter(expirationTime * 1000)
    if (isExpired) {
        console.log("Access Token Expired!")
        handleCookieDeleted(to)
    }
}

const handleCookieDeleted = (to) => {
    store.dispatch(AuthActions.LOG_OUT)
    store.dispatch(AuthActions.REDIRECT_AFTER_LOGIN, {
        name: to.name.toString(),
        params: to.params,
    })
}

const ResellerAuthCookiePlugin = {
    install(app, options) {
        app.config.globalProperties.$handleCookieDeleted = handleCookieDeleted

        const router = options.router

        router.beforeEach((to, from, next) => {
            const store = useStore()
            const isUserLoggedIn = store.getters.getSessionKey
            const accessToken = to?.redirectedFrom?.query?.accessToken
            const workspaceUrl = to?.redirectedFrom?.query?.wu
            const isLogInCreditialsPresent = accessToken && workspaceUrl

            if (
                resellerAssessmentHosts.includes(window.location.host) &&
                isUserLoggedIn &&
                !isLogInCreditialsPresent
            ) {
                checkTokenExpiration(isUserLoggedIn, to)
                checkLastRefreshed(to)
                next()
            } else {
                if (
                    resellerAssessmentHosts.includes(window.location.host) &&
                    isLogInCreditialsPresent
                ) {
                    setLastRefreshed()
                }
                next()
            }
        })
    },
}

export default ResellerAuthCookiePlugin
