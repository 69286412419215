import { createI18n } from "vue-i18n"

import en from "@/locales/en.json"

let locale = "en"

const i18n = createI18n({
    legacy: false,
    locale: locale,
    fallbackLocale: "en",
    globalInjection: true,
    messages: {
        en,
    },
})

export default i18n
