import { v4 as uuidv4 } from "uuid"
import {
    ADP_DEFAULT_SORT,
    ADP_DEFUALT_VISIBLE_COLUMNS,
    ALP_DEFUALT_VISIBLE_COLUMNS,
} from "../../../core/constants"
import {
    NotificationBanner,
    TypeAdpSessionData,
    TypeAllFiltersData,
    TypeLocale,
} from "./types"
export type State = {
    currentUserCountryDialCode: string
    currentUserTimeZone: string
    notificationBanner: NotificationBanner
    isReseller: boolean
    isDefaultReseller: boolean
    isPaidUser: boolean
    ADAcompliance: boolean
    isProfileDataLoaded: boolean
    locale: TypeLocale
    isCandidateDelete: boolean
    unReadNotificationCount: number
    sessionId: string
    isDrawerOpen: boolean
    isCompanyTestDrawerOpen: boolean
    resellerName: string
    resellerDomain: string
    isResellerActive: boolean
    manageLimit: boolean
    isDenyCreateWorkspace: boolean
    showPlansTab: object
    ADPSession: TypeAdpSessionData
    websiteFilters: TypeAllFiltersData
    isSupportLogin: boolean
    discoverPercentage: number
    ssosamlEnabled: boolean
    isValidCard: boolean
    defaultRegion: string
}

export const state: State = {
    notificationBanner: {
        show: false,
        message: "",
        bannerType: "",
        icon: "",
        classes: "",
        bannerTimeout: 0,
    },
    currentUserCountryDialCode: "",
    currentUserTimeZone: "",
    isReseller: false,
    isPaidUser: false,
    isProfileDataLoaded: false,
    isDefaultReseller: false,
    ADAcompliance: true,
    locale: {
        defaultLanguage: "en",
        languageList: [{ language: "English", code: "en" }],
        languageJson: {},
    },
    isCandidateDelete: false,
    unReadNotificationCount: 0,
    sessionId: uuidv4(),
    isDrawerOpen: false,
    isCompanyTestDrawerOpen: false,
    resellerName: "",
    resellerDomain: "",
    isResellerActive: true,
    manageLimit: false,
    isDenyCreateWorkspace: false,
    showPlansTab: {},
    ADPSession: {
        visibleTableHeadings: ADP_DEFUALT_VISIBLE_COLUMNS,
        checkListFilter: [],
        sortDetails: ADP_DEFAULT_SORT,
        filterDetails: {},
    },
    websiteFilters: {
        ALPFilterDetails: {},
        ALPVisibleTableHeadings: ALP_DEFUALT_VISIBLE_COLUMNS,
        CLPFilterDetails: {},
        TLPFilterDetails: {},
        TalentFilterDetails: {},
        QLPFilterDetails: {},
        userFilterDetails: {},
        assTestTabFilterDetails: {},
        assQueTabFilterDetails: {},
        companyQueTabFilterDetails: {},
    },
    isSupportLogin: false,
    discoverPercentage: 0,
    ssosamlEnabled: false,
    isValidCard: false,
    defaultRegion: "",
}
