import { computed } from "vue"
import { store } from "../store"
import { SUPPORT_EMAIL } from "@/core/constants"
import { ActionTypes as GlobalActions } from "@/store/modules/global/actions"

export default function ResellerHelper() {
    const getAuthUser = computed(() => store.getters.getAuthUser)

    const checkResellers = (resellerType: string) => {
        const authUserEmail = getAuthUser.value?.email
        const isDefaultReseller = resellerType === "default"
        const isJoinReseller =
            resellerType === "Join" && authUserEmail !== SUPPORT_EMAIL

        store.dispatch(GlobalActions.SET_IS_DEFAULT_RESELLER, isDefaultReseller)
        store.dispatch(GlobalActions.SET_IS_RESELLER, isJoinReseller)
    }

    const setResellerName = (resellerName: string) => {
        if (resellerName) {
            store.dispatch(GlobalActions.SET_RESELLER_NAME, resellerName)
        } else {
            store.dispatch(GlobalActions.SET_RESELLER_NAME, "")
        }
    }

    const setResellerDomain = (resellerDomain: string) => {
        if (resellerDomain) {
            store.dispatch(GlobalActions.SET_RESELLER_DOMAIN, resellerDomain)
        } else {
            store.dispatch(GlobalActions.SET_RESELLER_DOMAIN, "")
        }
    }
    const setResellerStatus = (resellerStatus: string) => {
        store.dispatch(GlobalActions.SET_RESELLER_STATUS, resellerStatus)
    }

    const setResellerManageLimitStatus = (manageLimitStatus: string) => {
        if (manageLimitStatus) {
            store.dispatch(
                GlobalActions.SET_RESELLER_MANAGE_LIMIT_STATUS,
                manageLimitStatus
            )
        } else {
            store.dispatch(GlobalActions.SET_RESELLER_MANAGE_LIMIT_STATUS, "")
        }
    }

    return {
        checkResellers,
        setResellerName,
        setResellerDomain,
        setResellerStatus,
        setResellerManageLimitStatus,
    }
}
