import { TypeCompanyQuestion, TypeCompanyTest } from "./types"

export type State = {
    companyTest: TypeCompanyTest
    openSingleSelectModal: boolean
    openMultipleSelectModal: boolean
    companyQuestions: TypeCompanyQuestion
    activeAccordion: string
    isAiTestInProgress: boolean
}

export const state: State = {
    companyTest: {
        title: "",
        questionCount: 0,
        timeLimit: 0,
        coveredSkillList: [],
        setTimeAt: "Test Level",
    },
    openSingleSelectModal: false,
    openMultipleSelectModal: false,
    companyQuestions: [],
    activeAccordion: "1",
    isAiTestInProgress: false,
}
