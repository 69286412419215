import { RootState } from "@/store"
import { ActionContext, ActionTree } from "vuex"
import { Mutations, MutationType } from "./mutations"
import { State } from "./state"
import { TypeWorkspace, WorkspaceAppearance } from "./types"

export enum ActionTypes {
    SET_WORKSPACE = "SET_WORKSPACE",
    SET_APPEARANCE = "SET_APPEARANCE",
    SET_WORKSPACE_ID = "SET_WORKSPACE_ID",
    SET_WORKSPACE_URL = "SET_WORKSPACE_URL",
    SET_WORKSPACE_NAME = "SET_WORKSPACE_NAME",
    SET_WORKSPACE_LOGO = "SET_WORKSPACE_LOGO",
    SET_SUBSCRIPTION_EXPIRE_DATE = "SET_SUBSCRIPTION_EXPIRE_DATE",
    SET_WORKSPACE_STATUS = "SET_WORKSPACE_STATUS",
    SET_IS_BILLING_ALLOWED = "SET_IS_BILLING_ALLOWED",
    SET_IS_TRIAL = "SET_IS_TRIAL",
    SET_IS_APPSUMO_COUPON_APPLIED = "SET_IS_APPSUMO_COUPON_APPLIED",
    SET_INTEGRATION_ADD_ON = "SET_INTEGRATION_ADD_ON",
    SET_WHITE_LABEL_ADD_ON = "SET_WHITE_LABEL_ADD_ON",
    SET_WHITELABEL = "SET_WHITELABEL",
    SET_IS_FULL_DISCOUNT = "SET_IS_FULL_DISCOUNT",
    SET_IS_SUBSCRIPTION_NAME = "SET_IS_SUBSCRIPTION_NAME",
    SET_IS_PAY_PER_USAGE_PLAN = "SET_IS_PAY_PER_USAGE_PLAN",
    SET_IS_LEGACY_PLAN = "SET_IS_LEGACY_PLAN",
    SET_CANDIDATE_NOTIFICATION = "SET_CANDIDATE_NOTIFICATION",
    SET_ADMIN_NOTIFICATION = "SET_ADMIN_NOTIFICATION",
    SET_EMAIL_TEMPLATE_NOTIFICATION = "SET_EMAIL_TEMPLATE_NOTIFICATION",
    SET_CUSTOM_TEMPLATE_ACTIVE = "SET_CUSTOM_TEMPLATE_ACTIVE",
    SET_SUPPORT_EMAIL = "SET_SUPPORT_EMAIL",
    SET_TERMS_URL = "SET_TERMS_URL",
    SET_PRIVACY_URL = "SET_PRIVACY_URL",
    SET_BRAND_COLOR = "SET_BRAND_COLOR",
    SET_BRAND_TEXT_COLOR = "SET_BRAND_TEXT_COLOR",
    SET_DAYS_LEFT_TRIAL = "SET_DAYS_LEFT_TRIAL",
    SET_TRIAL_EXTENDED = "SET_TRIAL_EXTENDED",
}

type ActionAugments = Omit<ActionContext<State, RootState>, "commit"> & {
    commit<K extends keyof Mutations>(
        key: K,
        payload: Parameters<Mutations[K]>[1]
    ): ReturnType<Mutations[K]>
}

export type Actions = {
    [ActionTypes.SET_WORKSPACE](
        { commit }: ActionAugments,
        obj: TypeWorkspace
    ): void
    [ActionTypes.SET_APPEARANCE](
        { commit }: ActionAugments,
        obj: WorkspaceAppearance
    ): void
    [ActionTypes.SET_WORKSPACE_URL](
        { commit }: ActionAugments,
        value: String
    ): void
    [ActionTypes.SET_WORKSPACE_ID](
        { commit }: ActionAugments,
        value: String
    ): void
    [ActionTypes.SET_WORKSPACE_NAME](
        { commit }: ActionAugments,
        value: String
    ): void
    [ActionTypes.SET_WHITELABEL](
        { commit }: ActionAugments,
        value: Boolean
    ): void
    [ActionTypes.SET_WORKSPACE_LOGO](
        { commit }: ActionAugments,
        value: String
    ): void
    [ActionTypes.SET_SUBSCRIPTION_EXPIRE_DATE](
        { commit }: ActionAugments,
        value: String
    ): void
    [ActionTypes.SET_WORKSPACE_STATUS](
        { commit }: ActionAugments,
        value: String
    ): void
    [ActionTypes.SET_IS_BILLING_ALLOWED](
        { commit }: ActionAugments,
        value: Boolean
    ): void
    [ActionTypes.SET_IS_FULL_DISCOUNT](
        { commit }: ActionAugments,
        value: Boolean
    ): void
    [ActionTypes.SET_IS_SUBSCRIPTION_NAME](
        { commit }: ActionAugments,
        value: String
    ): void
    [ActionTypes.SET_IS_PAY_PER_USAGE_PLAN](
        { commit }: ActionAugments,
        value: Boolean
    ): void
    [ActionTypes.SET_IS_LEGACY_PLAN](
        { commit }: ActionAugments,
        value: Boolean
    ): void
    [ActionTypes.SET_IS_TRIAL]({ commit }: ActionAugments, value: Boolean): void
    [ActionTypes.SET_DAYS_LEFT_TRIAL](
        { commit }: ActionAugments,
        value: Number
    ): void
    [ActionTypes.SET_TRIAL_EXTENDED](
        { commit }: ActionAugments,
        value: Boolean
    ): void

    [ActionTypes.SET_IS_APPSUMO_COUPON_APPLIED](
        { commit }: ActionAugments,
        value: Boolean
    ): void
    [ActionTypes.SET_INTEGRATION_ADD_ON](
        { commit }: ActionAugments,
        value: Boolean
    ): void
    [ActionTypes.SET_WHITE_LABEL_ADD_ON](
        { commit }: ActionAugments,
        value: Boolean
    ): void

    [ActionTypes.SET_CANDIDATE_NOTIFICATION](
        { commit }: ActionAugments,
        value: Boolean
    ): void
    [ActionTypes.SET_ADMIN_NOTIFICATION](
        { commit }: ActionAugments,
        value: Boolean
    ): void
    [ActionTypes.SET_CUSTOM_TEMPLATE_ACTIVE](
        { commit }: ActionAugments,
        value: Boolean
    ): void
    [ActionTypes.SET_EMAIL_TEMPLATE_NOTIFICATION](
        { commit }: ActionAugments,
        value: Boolean
    ): void
    [ActionTypes.SET_SUPPORT_EMAIL](
        { commit }: ActionAugments,
        value: String
    ): void
    [ActionTypes.SET_TERMS_URL]({ commit }: ActionAugments, value: String): void
    [ActionTypes.SET_PRIVACY_URL](
        { commit }: ActionAugments,
        value: String
    ): void
    [ActionTypes.SET_BRAND_COLOR](
        { commit }: ActionAugments,
        value: String
    ): void
    [ActionTypes.SET_BRAND_TEXT_COLOR](
        { commit }: ActionAugments,
        value: String
    ): void
}

export const actions: ActionTree<State, RootState> & Actions = {
    [ActionTypes.SET_WHITELABEL]({ commit }, value) {
        commit(MutationType.SET_WHITELABEL, value)
    },
    [ActionTypes.SET_CANDIDATE_NOTIFICATION]({ commit }, value) {
        commit(MutationType.SET_CANDIDATE_NOTIFICATION, value)
    },
    [ActionTypes.SET_ADMIN_NOTIFICATION]({ commit }, value) {
        commit(MutationType.SET_ADMIN_NOTIFICATION, value)
    },
    [ActionTypes.SET_EMAIL_TEMPLATE_NOTIFICATION]({ commit }, value) {
        commit(MutationType.SET_EMAIL_TEMPLATE_NOTIFICATION, value)
    },
    [ActionTypes.SET_CUSTOM_TEMPLATE_ACTIVE]({ commit }, value) {
        commit(MutationType.SET_CUSTOM_TEMPLATE_ACTIVE, value)
    },
    [ActionTypes.SET_WORKSPACE]({ commit }, obj) {
        commit(MutationType.SET_WORKSPACE_URL, obj.url)
        commit(MutationType.SET_WORKSPACE_NAME, obj.name)
        commit(MutationType.SET_WORKSPACE_LOGO, obj.logo)
    },
    [ActionTypes.SET_APPEARANCE]({ commit }, obj) {
        commit(MutationType.SET_BRAND_COLOR, obj.brandColor)
        commit(MutationType.SET_WORKSPACE_LOGO, obj.logo)
        commit(MutationType.SET_WORKSPACE_FAVICON, obj.favicon)
        commit(MutationType.SET_CUSTOM_IMAGE, obj.customImage)
        commit(MutationType.SET_BRAND_TEXT_COLOR, obj.brandTextColor)
    },
    [ActionTypes.SET_WORKSPACE_URL]({ commit }, value) {
        commit(MutationType.SET_WORKSPACE_URL, value)
    },
    [ActionTypes.SET_BRAND_COLOR]({ commit }, value) {
        commit(MutationType.SET_BRAND_COLOR, value)
    },
    [ActionTypes.SET_BRAND_TEXT_COLOR]({ commit }, value) {
        commit(MutationType.SET_BRAND_TEXT_COLOR, value)
    },
    [ActionTypes.SET_WORKSPACE_NAME]({ commit }, value) {
        commit(MutationType.SET_WORKSPACE_NAME, value)
    },
    [ActionTypes.SET_WORKSPACE_ID]({ commit }, value) {
        commit(MutationType.SET_WORKSPACE_ID, value)
    },
    [ActionTypes.SET_WORKSPACE_LOGO]({ commit }, value) {
        commit(MutationType.SET_WORKSPACE_LOGO, value)
    },
    [ActionTypes.SET_SUBSCRIPTION_EXPIRE_DATE]({ commit }, value) {
        commit(MutationType.SET_SUBSCRIPTION_EXPIRE_DATE, value)
    },
    [ActionTypes.SET_WORKSPACE_STATUS]({ commit }, value) {
        commit(MutationType.SET_WORKSPACE_STATUS, value)
    },
    [ActionTypes.SET_IS_BILLING_ALLOWED]({ commit }, value) {
        commit(MutationType.SET_IS_BILLING_ALLOWED, value)
    },
    [ActionTypes.SET_IS_TRIAL]({ commit }, value) {
        commit(MutationType.SET_IS_TRIAL, value)
    },
    [ActionTypes.SET_DAYS_LEFT_TRIAL]({ commit }, value) {
        commit(MutationType.SET_DAYS_LEFT_TRIAL, value)
    },
    [ActionTypes.SET_TRIAL_EXTENDED]({ commit }, value) {
        commit(MutationType.SET_TRIAL_EXTENDED, value)
    },

    [ActionTypes.SET_IS_APPSUMO_COUPON_APPLIED]({ commit }, value) {
        commit(MutationType.SET_IS_APPSUMO_COUPON_APPLIED, value)
    },
    [ActionTypes.SET_INTEGRATION_ADD_ON]({ commit }, value) {
        commit(MutationType.SET_INTEGRATION_ADD_ON, value)
    },
    [ActionTypes.SET_WHITE_LABEL_ADD_ON]({ commit }, value) {
        commit(MutationType.SET_WHITE_LABEL_ADD_ON, value)
    },
    [ActionTypes.SET_IS_FULL_DISCOUNT]({ commit }, value) {
        commit(MutationType.SET_IS_FULL_DISCOUNT, value)
    },
    [ActionTypes.SET_IS_SUBSCRIPTION_NAME]({ commit }, value) {
        commit(MutationType.SET_IS_SUBSCRIPTION_NAME, value)
    },
    [ActionTypes.SET_IS_PAY_PER_USAGE_PLAN]({ commit }, value) {
        commit(MutationType.SET_IS_PAY_PER_USAGE_PLAN, value)
    },
    [ActionTypes.SET_IS_LEGACY_PLAN]({ commit }, value) {
        commit(MutationType.SET_IS_LEGACY_PLAN, value)
    },
    [ActionTypes.SET_SUPPORT_EMAIL]({ commit }, value) {
        commit(MutationType.SET_SUPPORT_EMAIL, value)
    },
    [ActionTypes.SET_TERMS_URL]({ commit }, value) {
        commit(MutationType.SET_TERMS_URL, value)
    },
    [ActionTypes.SET_PRIVACY_URL]({ commit }, value) {
        commit(MutationType.SET_PRIVACY_URL, value)
    },
}
